import styled from "styled-components";
import Header from "./header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const Web = () => {
  useEffect(() => {
    AOS.init();
  });
  return (
    <Main>
      <Header />
      <First>
        <div className="opening">
          <div className="leftdiv">
            <h1>E-Commerce Application Development</h1>
            <p>
              At <span>Satvidi</span>, we develop an interactive online platform
              that connects multiple vendors and customers via customizable
              E-Commerce solutions.
            </p>
          </div>
          <div className="rightdiv">
            <img src="ecom1.gif" alt="" />
          </div>
        </div>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="ecomweb3.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>B2C Website</h1>
            <p>
              We offer B2C website with for an extensive customer base with
              Pre-order management, marketing and promotion suite and Automated
              tax calculation features.
            </p>
          </div>
        </div>
      </First>
      <Second>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="b2b.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>B2B Website</h1>
            <p>
              We offer B2B Website with buyer segmentation, quote management,
              price negotiation and ship-to functionality features
            </p>
          </div>
        </div>
      </Second>
      <Third>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="ehub2.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>E-Hub and Multi-Store</h1>
            <p>
              We offer online multi-vendor and multi store management e-commerce
              features with Multingual and Multi-currency support
            </p>
          </div>
        </div>
      </Third>
      <Fourth>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="analytics.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>E-Commerce Reports</h1>
            <p>
              We <span>Satvidi</span>, offer wide range of user specificreports:
              <br />
              Marketplace-wide and seller-specific analytical reports,
              multistore-wide and store-specific, order reports.
            </p>
          </div>
        </div>
        <div className="btn-contact">
          <p>Ready to Ask Us?</p>
          <a href="contact us">contact now</a>
        </div>
      </Fourth>

      <Footer>
        <div className="footer">
          <div className="atags">
            <a href="/">Home/</a>
            <a href="/web">Web App/</a>
            <a href="/mobile">Mobile App/</a>
            <a href="/ecom">E-commerce /</a>

            <a href="contact us">Contact us</a>
          </div>
          <p>
            <i class="fa fa-copyright"></i> 2021 Satvidi Technologies
          </p>
        </div>
      </Footer>
    </Main>
  );
};
const Footer = styled.div`
  height: 20vh;
  width: 100%;
  .footer {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: black;
    a {
      padding: 0 0 0 5px;
      color: white;
      font-family: "PT Serif", serif;

      @media (max-width: 700px) {
        font-size: 12px;
      }
    }
    a:hover {
      color: blue;
    }
    p {
      color: white;
      text-align: center;
      font-size: 15px;
      i {
        color: white;
      }
    }
  }
`;
const Main = styled.div`
  background-color: #0f123d;
  color: white;
  overflow-x: hidden;

  h1 {
    color: #bee4f2;
    letter-spacing: 1px;
    padding: 40px;
    font-family: "PT Serif", serif;

    @media (max-width: 700px) {
      padding: 20px;
      font-size: 25px;
    }
  }

  p {
    color: white;
    padding-left: 45px;
    padding-bottom: 5px;
    width: 75%;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    word-spacing: 1px;
    font-family: "PT Serif", serif;

    @media (max-width: 700px) {
      padding-left: 30px;
      padding-bottom: 10px;
      font-size: 15px;
      width: 95%;
      line-height: 25px;
    }
    span {
      color: #f9562f;
      font-family: "PT Serif", serif;
    }
  }
`;
const First = styled.div`
  .opening {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }

    .leftdiv {
      width: 80%;
      margin-top: 8%;
      @media (max-width: 700px) {
        margin-top: 20%;
      }
      h1 {
        @media (max-width: 700px) {
          width: 150%;
        }
      }
      p {
        width: 90%;
        font-size: 25px;
        margin-top: 30px;
        line-height: 30px;
        @media (max-width: 700px) {
          width: 120%;
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
    .rightdiv {
      width: 40%;
      img {
        padding-top: 80px;
        @media (max-width: 700px) {
          width: 220%;
          padding-left: 20px;
        }
      }
    }
  }
  .container {
    height: 75vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 5%;
    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: 10%;
      margin-left: 10%;
    }
  }
  .pic img {
    width: 500px;
    padding-left: 30px;
    @media (max-width: 700px) {
      width: 300px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 50px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
  }
`;
const Second = styled.div`
  .container {
    height: 75vh;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      margin-top: -5%;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-left: -1%;
      margin-left: 15%;
    }
  }
  .pic img {
    width: 450px;

    @media (max-width: 700px) {
      width: 300px;
      margin-top: 0;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
const Third = styled.div`
  .container {
    height: 75vh;

    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -1%;
      margin-left: 15%;
    }
  }
  .pic img {
    width: 450px;
    margin-left: 40px;
    @media (max-width: 700px) {
      width: 300px;
      margin-left: 0;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
const Fourth = styled.div`
  .container {
    height: 150vh;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      height: 90vh;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: -10%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -20%;
      margin-left: 15%;
    }
  }
  .pic img {
    width: 460px;
    @media (max-width: 700px) {
      width: 300px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -80px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
  .bottdiv {
    p {
      width: 80%;
      margin-top: -5%;
      padding-left: 80px;
      margin-bottom: 5%;
      @media (max-width: 700px) {
        width: 100%;
        padding-left: 10px;
      }
    }
  }
  .btn-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    height: 20vh;
    background-image: url("Meteor.svg");

    @media (max-width: 700px) {
      margin-top: -10%;
    }
    p {
      color: black;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      font-family: "PT Serif", serif;
    }
    a {
      margin-top: 2%;
      text-decoration: none;
      background-color: #1c75cf;
      font-family: "PT Serif", serif;

      padding: 10px 20px 10px 20px;
      border: 1px solid blue;
      border-radius: 50px;
      color: white;
      transition: 0.5s;
    }
    a:hover {
      background-color: #000000;
      color: white;

      transition: 0.5s;
      border: 1px solid whitesmoke;
    }
  }
`;

export default Web;

import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home";
import Mobile from "./components/mobile";
import Ecom from "./components/ecom";
import Web from "./components/web";
import Contact from "./components/contact";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/contact us">
          <Contact />
        </Route>
        <Route path="/mobile">
          <Mobile />
        </Route>
        <Route path="/web">
          <Web />
        </Route>
        <Route path="/ecom">
          <Ecom />
        </Route>

        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

import styled from "styled-components";
import Header from "./header";
import emailjs from "emailjs-com";
import { useState } from "react";
import React from "react";

const ContactUs = () => {
  const [message, setMessage] = useState(true);

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ha8ylfn",
        "template_1x9kb8g",
        e.target,
        "user_Y4JYEHL5Bmn5eU60EIiUn"
      )
      .then(
        (result) => {
          setMessage(false);
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <Container>
      <Header />
      {message && (
        <div>
          <div>
            <h1>Contact Us</h1>
            <p>Send your details to reach you soon.</p>
          </div>

          <div className="contactDiv">
            <div className="customers">
              <form className="contact-form" onSubmit={sendEmail}>
                <input
                  className="name"
                  type="text"
                  placeholder="Name"
                  name="name"
                />

                <input
                  className="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                />

                <input
                  className="phoneno"
                  type="text"
                  placeholder="Phone No"
                  name="phoneno"
                />

                <textarea
                  className="message"
                  placeholder="Message"
                  name="message"
                />
                <input className="submit" type="submit" value="SUBMIT" />
              </form>
            </div>
          </div>
        </div>
      )}
      {!message && (
        <div className="messagediv">
          <p>
            Thanks for your responce <br />
            We will get back to you shortly, have a nice day :)
          </p>
          <div className="okbtn">
            <button onClick={() => setMessage(true)}>Okay</button>
          </div>
        </div>
      )}
      <div className="hero">
        <img src="contact5.jpg" alt="" />
      </div>
    </Container>
  );
};
const Container = styled.div`
  .hero {
    margin-top: -55%;
    @media (max-width: 700px) {
      /* visibility: hidden;
      margin-top: -48%; */
      display: none;
    }
    img {
      width: 300px;
      height: 60vh;
      @media (max-width: 700px) {
        position: fixed;
        width: 150px;
        height: 20vh;
        margin-top: -100px;
        margin-left: 30%;
      }
    }
  }

  background-color: #0f123d;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 700px) {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
  }
  .messagediv {
    width: 50%;
    height: 50vh;
    position: absolute;
    left: 25%;
    top: 30%;

    background-image: url("contactbg.svg");
    border-radius: 20px;
    transition: 1s ease-in-out;
    .okbtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10%;
      @media (max-width: 700px) {
        margin-top: 30%;
      }
      button {
        padding: 5px 20px 5px 20px;
        border-radius: 5px;
        color: white;
        background-color: #f9562f;
        border: 1px solid black;
        /* outline: none; */
        font-family: "PT Sans Caption", sans-serif;
        font-size: 15px;
        cursor: pointer;
        transition: 0.5s;
      }
      button:hover {
        background-color: black;
        color: white;
        transition: 0.5s;
      }
    }
    @media (max-width: 700px) {
      width: 80%;
      height: 40vh;
      left: 10%;
    }
    p {
      color: white;
      letter-spacing: 1px;
      padding-top: 15%;
      text-align: center;
      line-height: 30px;
      font-size: 20px;
    }
  }

  .up {
    margin-top: -10%;
    width: 100px;
    margin-left: 64%;
    @media (max-width: 700px) {
      margin-top: 10%;
      margin-left: 120%;
    }
  }
  .contactDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: 10%;
      margin-left: -16%;
    }
    @media (max-width: 1300px) {
      margin-bottom: 20%;
    }
  }
  h1 {
    color: white;
    text-align: center;
    font-size: 80px;

    margin-top: 5%;

    font-family: "PT Sans Caption", sans-serif;
    @media (max-width: 700px) {
      font-size: 50px;
      margin-top: 20%;
    }
  }
  p {
    text-align: center;
    color: #92e3a9;
    font-size: 20px;
    font-family: "PT Serif", serif;

    @media (max-width: 700px) {
      font-size: 15px;
    }
  }
  .ours {
    margin-top: 15%;
    height: 100vh;
    width: 50%;

    margin-left: 7%;
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    @media (max-width: 700px) {
      width: 100%;
    }
    @media (max-width: 1300px) {
      margin-top: -20px;
    }
  }
  .customers {
    height: 80vh;
    width: 50%;
    margin-left: 10%;
  }

  .customers {
    color: white;
    margin-top: 5%;
    @media (max-width: 700px) {
      margin-top: 10%;
    }
    @media (max-width: 1300px) {
      margin-top: 0;
    }
  }
  .customers form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
  .customers form input {
    margin: 2%;
    width: 80%;
    padding: 10px;
    background-color: transparent;
    color: white;
    font-family: "PT Serif", serif;

    outline: none;
    font-size: 20px;
    border: 1px white solid;
    border-radius: 5px;
    @media (max-width: 700px) {
      width: 140%;
    }
  }
  .customers form .name {
    background-color: transparent;
  }

  .customers form textarea {
    margin: 2%;
    border: 1px white solid;
    width: 80%;
    padding: 10px;
    background-color: transparent;
    color: white;
    font-family: "PT Serif", serif;

    outline: none;
    font-size: 20px;
    height: 15vh;
    border-radius: 5px;
    @media (max-width: 700px) {
      width: 140%;
    }
  }
  .customers form .submit {
    border: black 1px solid;
    width: 60%;
    border-radius: 50px;
    margin-left: 13%;
    background-color: white;
    color: black;
    transition: 0.5s;
    font-family: "PT Serif", serif;
    @media (max-width: 700px) {
      margin-top: 10%;
      margin-left: -3%;
      width: 150%;
    }
  }
  .customers form .submit:hover {
    background-color: black;
    color: white;
    transition: 0.5s;
    box-shadow: 0 0 3px 2px white;
  }
  .ours .heading {
    font-size: 30px;
    color: white;
    font-family: "PT Serif", serif;

    margin-top: -5%;
    @media (max-width: 700px) {
      margin-top: 20%;
    }
  }
  .span1 {
    color: white;
    font-size: 25px;
    font-family: "PT Serif", serif;

    text-decoration: underline;
    margin-top: -20%;
    @media (max-width: 700px) {
      margin-top: -20%;
    }
  }
  .gmail {
    color: white;
    margin-top: -25%;
    font-size: 17px;
    font-family: "PT Serif", serif;

    text-decoration: underline;
    @media (max-width: 700px) {
      margin-top: -30%;
    }
  }
  .social {
    display: flex;

    @media (max-width: 700px) {
      margin-top: -20%;
    }
  }
  .social a {
    width: 200px;
    margin-top: -35%;
    @media (max-width: 700px) {
      margin-top: -10%;
    }
  }
`;
export default ContactUs;

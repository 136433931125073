import styled from "styled-components";
import Header from "./header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";

const Home = () => {
  useEffect(() => {
    AOS.init();
  });
  const CarouselUI = ({ children }) => <Container>{children}</Container>;
  const Carousel = makeCarousel(CarouselUI);
  return (
    <Main>
      <div className="homediv">
        <Header />
        <First>
          <div className="logo">
            <img src="logoo2.png" alt="" />
          </div>
          <div className="left">
            <p>
              <span>You Ask,</span>
              We Hear
            </p>
            <span className="sub">
              To provide IT services to cater multi domain customer needs
            </span>
          </div>

          <div className="right">
            <Carousel
              defaultWait={3000}
              maxTurns={Infinity} /*wait for 1000 milliseconds*/
            >
              <Slide left>
                <div className="img1">
                  <img src="caro1.png" alt="" />
                </div>
              </Slide>
              <Slide left>
                <div className="img2">
                  <img src="caro4.png" alt="" />
                </div>
              </Slide>
              <Slide left>
                <div className="img2">
                  <img src="firstImg.png" alt="" />
                </div>
              </Slide>
              <Slide left>
                <div className="img2">
                  <img src="caro2.png" alt="" />
                </div>
              </Slide>
              <Slide left>
                <div className="img2">
                  <img src="caro3.png" alt="" />
                </div>
              </Slide>
            </Carousel>
          </div>
        </First>
        <Second id="second">
          <div className="cen" data-aos="fade-up">
            <img src="about2.gif" alt="" />
            <span className="tag">
              <p className="abouthead">Satvidi Technologies</p>
              <p className="abouttext">
                A customer oriented consulting firm focused on designing and
                developing Product Engineering, Enterprise Mobility and Custom
                Application Development with Cross Platform features.
                <br /> Our customers eulogize our end to end services are
                reliable and value-driven in multiple ways.
              </p>
            </span>
          </div>
          <div className="computer" data-aos="fade-up">
            <img src="Business_PNG.png" alt="" />
          </div>
        </Second>
        <Third id="services">
          <div className="services">
            <div className="head">
              <div className="left"></div>
              <span>services</span>
              <div className="right"></div>
            </div>

            <div className="tag">
              <p>
                Get all the services you need <span>Under one Roof</span>
              </p>
            </div>

            <div className="ser">
              <a
                href="/web"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <img src="web2.gif" alt="" />
                <span>Web App</span>
              </a>
              <a
                href="/mobile"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="150"
                data-aos-duration="1000"
              >
                <img src="mobile2.gif" alt="" />
                <span>Mobile App</span>
              </a>
              <a
                href="/ecom"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="300"
                data-aos-duration="1000"
              >
                <img src="eCommerce2.gif" alt="" />
                <span>E-Commerce</span>
              </a>
            </div>
          </div>
          <div className="btn-contact">
            <p>Ready to Ask Us?</p>
            <a href="contact us">Contact Now</a>
          </div>
          <div className="footer">
            <div className="atags">
              <a href="/">Home/</a>
              <a href="/web">Web App/</a>
              <a href="/mobile">Mobile App/</a>
              <a href="/ecom">E-commerce /</a>

              <a href="contact us">Contact us</a>
            </div>
            <p>
              <i class="fa fa-copyright"></i> 2021 Satvidi Technologies
            </p>
          </div>
        </Third>
      </div>
    </Main>
  );
};
const Logo = styled.div``;
const Container = styled.div`
  border: 1px solid red;
  position: relative;
  overflow: hidden;
  width: 780px;
  height: 88vh;
  border: none;
  margin-left: 100px;
  @media (max-width: 700px) {
    margin-left: 0px;
  }
`;
const Main = styled.div`
  overflow-x: hidden;
`;
const Second = styled.div`
  background-color: #0f123d;
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    height: 70vh;
  }

  .cen {
    width: 90%;
    height: 70vh;
    border-right: #64cbe9 solid 2px;
    border-bottom: #64cbe9 solid 2px;
    background-color: #2c2b6a;
    border-top-left-radius: 20%;
    border-bottom-left-radius: 20%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 0px 7px 0px #50b8dc;
    @media (max-width: 700px) {
      margin-top: -10%;
      margin-left: 2%;
      width: 150%;
      height: 40vh;
      border-top-right-radius: 20%;
      border-bottom-left-radius: 20%;
    }
    img {
      width: 500px;
      border-top-right-radius: 60%;
      border-bottom-right-radius: 45%;
      background-color: #0f123d;
      margin-bottom: 10px;
      margin-left: -2%;
      @media (max-width: 700px) {
        width: 145px;
        margin-top: -130%;
        margin-left: -5%;
      }
    }

    .tag {
      @media (max-width: 700px) {
        width: 100%;
      }
      .abouttext {
        margin-left: 2%;
        font-size: 20px;
        font-family: "PT Serif", serif;
        letter-spacing: 1px;
        word-spacing: 2px;

        color: white;
        font-weight: 500;
        @media (max-width: 700px) {
          font-size: 15px;
          margin-left: -65%;
        }
      }
      .abouthead {
        font-size: 35px;
        color: #50b8dc;
        margin-top: -3%;
        margin-bottom: 10px;
        font-family: "PT Serif", serif;

        @media (max-width: 700px) {
          font-size: 20px;
          margin-left: -50%;
          margin-top: 2%;
        }
      }
    }
  }
  .computer {
    margin-left: -7%;
    margin-top: 30%;
    @media (max-width: 700px) {
      margin-left: -30%;
      margin-top: 100%;
    }
  }
  .computer img {
    width: 250px;
    @media (max-width: 700px) {
      width: 150px;
    }
  }
`;
const Third = styled.div`
  background-color: #0f123d;
  height: 155vh;
  width: 100%;
  @media (max-width: 700px) {
    height: 240vh;
  }
  .btn-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    height: 20vh;
    background-image: url("Meteor.svg");

    @media (max-width: 700px) {
      margin-top: -10%;
      height: 20vh;
    }
    p {
      color: black;
      font-size: 30px;
      font-weight: 600;
      font-family: "PT Serif", serif;
    }
    a {
      margin-top: 2%;
      text-decoration: none;
      background-color: #1c75cf;
      font-family: "PT Serif", serif;

      padding: 10px 20px 10px 20px;
      border: 1px solid blue;
      border-radius: 50px;
      color: white;
      transition: 0.5s;
    }
    a:hover {
      background-color: #000000;
      color: white;

      transition: 0.5s;
      border: 1px solid whitesmoke;
    }
  }

  .tag {
    p {
      text-align: center;
      font-size: 25px;
      font-family: "PT Serif", serif;

      color: #50b8dc;
      @media (max-width: 700px) {
        font-size: 20px;
      }
    }
    span {
      color: #ec98c2;
      font-family: "PT Serif", serif;

      @media (max-width: 700px) {
        margin-bottom: 2%;
      }
    }
  }
  .services {
    overflow: hidden;
    height: 110vh;
    @media (max-width: 700px) {
      height: 200vh;
    }

    .head {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5%;
      @media (max-width: 1300px) {
        margin-top: 5%;
      }
      .right {
        border-bottom: 2px solid #cce0d2;
        width: 20%;
      }
      .left {
        border-bottom: 2px solid #cce0d2;
        width: 20%;
      }

      span {
        color: white;
        padding: 20px;
        font-size: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        justify-content: center;
        font-weight: 550;
        font-family: "PT Serif", serif;

        text-transform: uppercase;
      }
    }
    .ser {
      padding: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      @media (max-width: 700px) {
        flex-direction: column;
        justify-content: space-evenly;
      }

      a img {
        border-radius: 10px;
        width: 300px;
        border-right: #64cbe9 solid 1px;
        border-bottom: #64cbe9 solid 1px;
        transition: 0.5s;
      }
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
      a span {
        text-decoration: none;

        font-size: 20px;
        letter-spacing: 1px;
        font-family: "PT Serif", serif;

        font-weight: 400;
        margin-top: 5%;
        color: #64cbe9;
      }
      a img:hover {
        box-shadow: 0px 0px 7px 2px #64cbe9;
        transition: 0.5s;
      }
    }
  }

  .footer {
    height: 24.5vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: black;
    a {
      padding: 0 0 0 5px;
      color: white;
      font-family: "Montserrat", sans-serif;
      @media (max-width: 700px) {
        font-size: 12px;
      }
    }
    a:hover {
      color: blue;
    }
    p {
      color: white;
      font-family: "PT Serif", serif;

      i {
        color: white;
      }
    }
  }
`;
const First = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;

  background-image: linear-gradient(
    #a65486,
    #2c2b6a,
    #0f123d,
    #0f123d,
    #0f123d
  );
  .logo img {
    width: 400px;
    margin-left: 10%;
    margin-top: -10%;

    @media (max-width: 1300px) {
      margin-left: 2%;
    }
    @media (max-width: 700px) {
      width: 250px;
      margin-top: -4%;
      margin-left: -2%;
      /* overflow: hidden; */
    }
  }
  .left {
    margin-left: -25%;
    margin-top: 10%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: 1300px) {
      margin-left: -30%;
    }
    @media (max-width: 700px) {
      width: 100%;
      margin-top: 100%;
      margin-left: -60%;
    }
    .main {
      padding: 10px;
      color: white;
      text-shadow: 1px 1px 2px white;
      font-size: 50px;
      text-transform: uppercase;

      margin-left: 6%;
      margin-top: -5%;
      font-family: "PT Serif", serif;
      font-weight: 500;
    }
    .next {
      padding: 10px;
      color: #f9562f;
      font-size: 50px;
      text-transform: uppercase;
      margin-left: 10%;
      margin-top: -5%;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
    }
    .sub {
      color: white;
      padding-left: 65px;
      font-size: 25px;
      letter-spacing: 0.5px;
      word-spacing: 2px;
      margin-top: 5px;
      font-family: "Roboto Slab", serif;
      font-weight: 500;

      @media (max-width: 700px) {
        margin-left: -17%;

        font-size: 15px;
        width: 100%;
      }
    }
    p {
      margin-top: 150px;
      padding-left: 15px;

      font-size: 35px;
      color: white;
      letter-spacing: 0.5px;
      word-spacing: 2px;

      margin-left: 10%;

      font-family: "Cormorant Garamond", serif;

      span {
        font-family: "Cormorant Garamond", serif;

        color: #f9562f;
        padding-right: 5px;
      }

      @media (max-width: 700px) {
        font-size: 25px;
        margin-top: -160%;
        margin-left: -5%;
      }
    }
  }
  .right {
    margin-left: 1%;
    margin-top: -5%;
    margin-top: 7%;
    margin-right: -15%;
    width: 50%;

    img {
      width: 70%;
      height: 85vh;
    }
    @media (max-width: 700px) {
      margin-top: 100%;
      margin-left: -105%;
      width: 100%;
      img {
        width: 350px;
        height: 40vh;
      }
    }
  }
`;
export default Home;

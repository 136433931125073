import styled from "styled-components";
import Header from "./header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const Web = () => {
  useEffect(() => {
    AOS.init();
  });
  return (
    <Main>
      <Header />
      <First>
        <div className="opening">
          <div className="leftdiv">
            <h1>Web Design and Development</h1>
            <p>
              Design is all about creativity and us, <span>Satvidi</span> are
              specialized and quality driven service provider in designing and
              developing the niche website. Our dedicated team always provides
              excellence in their work. so, we consider as one of the best Web
              Designing and Development Company.
              <br />
              We offer services to small, medium and large businesses to meet
              all kind of needs.
            </p>
          </div>
          <div className="rightdiv">
            <img src="Development.gif" alt="" />
          </div>
        </div>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="portal3.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>Portal Development</h1>
            <p>
              Information plays a vital role in any organization,{" "}
              <span>Satvidi</span> often does portal websites by collecting
              information from various sources in a uniform way and serves the
              single point of access and presents the users with more relevant
              information.
            </p>
          </div>
        </div>
      </First>
      <Second>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="customweb.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>Custom Web App Development</h1>
            <p>
              <span>Satvidi</span> helps client's business by building web
              application which utilizes the rational database and dynamic
              functionality.
              <br /> We custom web application using various technologyes and
              frameworks such as PHP, MySql, Codeigniter, .Net and Sql.
              <br /> We are specialized in building a web application with
              flexible technologies, user interface, dashboards, cloud hosted
              and mobile friendly responsive functionalities.
            </p>
          </div>
        </div>
      </Second>
      <Third>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="supportweb2.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>Website Development and Support</h1>
            <p>
              We articulate the web design and development, along with we also
              offer round the clock support and maintenance ta all its
              subscribers as well as the clients.
              <br /> We extend our exclusive support services through blogs,
              forums and emails.
            </p>
          </div>
        </div>
      </Third>
      <Fourth>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="ecomweb2.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>E-Commerce Development</h1>
            <p>
              We work closely with the marketing team and product manager to
              implement strategies and provide industry best solutions to
              existing companies.
              <br /> We design latest and modern interfaces which bring life to
              the E-Commerce site by using imagery, illustrations and animations
              that help in user engagement as well as conversions.
            </p>
          </div>
        </div>
      </Fourth>
      <Five>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="customweb2.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>SAAS Products</h1>
            <p>
              Platform independence is one of the major concerns in building new
              innovative products, on other side product development has taken a
              sharp turn and it is shifting towards web technologies which leads
              to rising in the demand for SAAS Applications.
              <br />
              <br />
              We are expertise in,
              <br />
              1. SAAS Application Development
              <br />
              2. SAAS Application Optimization
              <br />
              3. SAAS Application Design
              <br />
              4. Technology Migration
              <br />
              5. Support and Maintenance
              <br />
              6. Independent Testing
              <br />
              <br />
              With our unique and excellent SAAS solution we help you to get out
              of those obstacles and by multiple ways.
            </p>
          </div>
        </div>
      </Five>
      <Six>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="openweb2.gif" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>Open Source Web Application Development</h1>
            <p>
              Open source web application enables end to end customization and
              outstanding performance which helps in spreading thier business
              across the globe.
              <br /> Open source technologies are highly used for small-medium
              enterprises to giant enterprises.
              <br />
              With easy customization and innovative designs, open source is now
              a preferred option for web development companies and business as
              well.
              <br />
              <br />
              Our Open Source Web Application services are,
              <br />
              1. PHP web development service
              <br />
              2. Magento e-commerce
              <br />
              3. Wordpress Development
              <br />
              4. Laravel development
              <br />
              5. Nopcommerce
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="bottdiv">
          <p>
            Seeking an open source web development team to build and manage your
            business website or online store? you are in the right place.
            <br />
            Hire our open source developers today and get cutting-edge solutions
            for your ambitious web project.
          </p>
        </div>
        <div className="btn-contact">
          <p>Ready to Ask Us?</p>
          <a href="contact us">contact now</a>
        </div>
      </Six>
      <Footer>
        <div className="footer">
          <div className="atags">
            <a href="/">Home/</a>
            <a href="/web">Web App/</a>
            <a href="/mobile">Mobile App/</a>
            <a href="/ecom">E-commerce /</a>

            <a href="contact us">Contact us</a>
          </div>
          <p>
            <i class="fa fa-copyright"></i> 2021 Satvidi Technologies
          </p>
        </div>
      </Footer>
    </Main>
  );
};
const Footer = styled.div`
  height: 20vh;
  width: 100%;

  .footer {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: black;
    a {
      padding: 0 0 0 5px;
      color: white;
      font-family: "PT Serif", serif;
      @media (max-width: 700px) {
        font-size: 12px;
      }
    }
    a:hover {
      color: blue;
    }
    p {
      color: white;
      text-align: center;
      font-size: 15px;
      i {
        color: white;
      }
    }
  }
`;
const Main = styled.div`
  background-color: #0f123d;
  color: white;
  overflow-x: hidden;

  h1 {
    color: #bee4f2;
    letter-spacing: 1px;
    padding: 40px;
    font-family: "PT Serif", serif;

    @media (max-width: 700px) {
      padding: 20px;
      font-size: 25px;
    }
  }

  p {
    color: white;
    padding-left: 45px;
    padding-bottom: 5px;
    width: 75%;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.5px;
    word-spacing: 1px;
    font-family: "PT Serif", serif;

    @media (max-width: 700px) {
      padding-left: 30px;
      padding-bottom: 10px;
      font-size: 15px;
      line-height: 25px;
      width: 95%;
    }
    span {
      color: #f9562f;
      font-family: "PT Serif", serif;
    }
  }
`;
const First = styled.div`
  .opening {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
    .leftdiv {
      width: 80%;
      margin-top: 6%;
      @media (max-width: 700px) {
        margin-top: 20%;
      }
      h1 {
        @media (max-width: 700px) {
          width: 100%;
        }
      }
      p {
        width: 100%;
        font-size: 22px;
        margin-top: 30px;
        line-height: 30px;
        @media (max-width: 700px) {
          width: 120%;
          margin-top: 10px;
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
    .rightdiv {
      width: 40%;
      @media (max-width: 700px) {
        margin-top: -5%;
        /* margin-left: -5%; */
      }
      img {
        width: 600px;
        padding-right: 60px;
        margin-top: 60px;
        @media (max-width: 700px) {
          width: 400px;
          margin-top: 8px;
        }
      }
    }
  }
  .container {
    height: 85vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 5%;
    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -25%;
      margin-left: 25%;
    }
  }
  .pic img {
    width: 500px;
    @media (max-width: 700px) {
      width: 250px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 50px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
  }
`;
const Second = styled.div`
  .container {
    height: 85vh;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      margin-bottom: 15%;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -1%;
      margin-left: 25%;
    }
  }
  .pic img {
    width: 500px;
    @media (max-width: 700px) {
      width: 250px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
const Third = styled.div`
  .container {
    height: 85vh;

    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      margin-bottom: 15%;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -1%;
      margin-left: 25%;
    }
  }
  .pic img {
    width: 500px;
    @media (max-width: 700px) {
      width: 250px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
const Fourth = styled.div`
  .container {
    height: 85vh;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      margin-bottom: 15%;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -1%;
      margin-left: 25%;
    }
  }
  .pic img {
    width: 500px;
    @media (max-width: 700px) {
      width: 250px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
const Five = styled.div`
  .container {
    height: 85vh;

    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      margin-bottom: 15%;
      margin-top: 30%;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -1%;
      margin-left: 25%;
    }
  }
  .pic img {
    width: 500px;
    @media (max-width: 700px) {
      width: 250px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 50px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
const Six = styled.div`
  .container {
    height: 120vh;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 1300px) {
      margin-top: 80px;
    }
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      height: 180vh;
      margin-top: -60%;
      margin-bottom: 15%;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;

      margin-left: 5%;
      margin-top: -50%;
    }
  }
  .pic img {
    width: 500px;
    @media (max-width: 700px) {
      margin-bottom: -20%;
      width: 350px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 60%;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
  .bottdiv {
    @media (max-width: 700px) {
      margin-top: 20%;
    }
    p {
      width: 80%;
      margin-top: -5%;
      padding-left: 80px;
      margin-bottom: 20%;
      @media (max-width: 700px) {
        width: 100%;
        padding-left: 10px;
        margin-top: -20%;
      }
    }
  }
  .btn-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    height: 20vh;
    background-image: url("Meteor.svg");
    @media (max-width: 700px) {
      margin-top: -10%;
    }
    p {
      color: black;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      font-family: "PT Serif", serif;
    }
    a {
      margin-top: 2%;
      text-decoration: none;
      background-color: #1c75cf;
      font-family: "PT Serif", serif;
      padding: 10px 20px 10px 20px;
      border: 1px solid blue;
      border-radius: 50px;
      color: white;
      transition: 0.5s;
    }
    a:hover {
      background-color: #000000;
      color: white;
      transition: 0.5s;
      border: 1px solid whitesmoke;
    }
  }
`;

export default Web;

import styled from "styled-components";
import { useState } from "react";
import "./header.css";
const Header = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <Nav className={colorChange ? "navbar colorChange" : "navbar"}>
      <div className="mobile">
        <input type="checkbox" id="active" />
        <label for="active" class="menu-btn">
          <i class="fas fa-bars"></i>
        </label>
        <div class="wrapper">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>

            <li>
              <div class="dropdown-Mobile">
                <a href="#">services</a>
                <a href="/web">Web App</a>
                <a href="/mobile">Mobile App</a>

                <a href="/ecom">Ecommerce</a>
              </div>
            </li>
            <li>
              <a href="/contact us">contact us</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="pc">
        <div className="right">
          <img src="logoo2.png" alt="" />
        </div>
        <div className="left">
          <a href="/">Home</a>
          <div class="dropdown">
            <button class="dropbtn">
              Services
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content">
              <a href="/web">Web App</a>
              <a href="/mobile">Mobile App</a>

              <a href="/ecom">Ecommerce</a>
            </div>
          </div>

          <a href="/contact us">Contact Us </a>
        </div>
      </div>
    </Nav>
  );
};
const Nav = styled.div`
  position: fixed;
  z-index: 100;
  background-color: transprent;
  width: 100%;
  height: 15vh;
  padding: 10px;
  .pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15vh;
    transition: 0.5s;
    .right img {
      width: 150px;
    }
    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        text-decoration: none;
        padding: 5px 20px 5px 20px;
        margin: 0 10px 0 10px;
        color: white;
        font-family: "PT Sans Caption", sans-serif;

        transition: 0.5s;
      }
      a:hover {
        color: black;
        background-color: #f9562f;
        border-radius: 50px;
        transition: 0.5s;
      }
    }
  }
`;

export default Header;

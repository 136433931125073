import styled from "styled-components";
import Header from "./header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const Web = () => {
  useEffect(() => {
    AOS.init();
  });
  return (
    <Main>
      <Header />
      <First>
        <div className="opening">
          <div className="leftdiv">
            <h1>Mobile Application Development</h1>
            <p>
              Online Services are at fingertips with Mobile and{" "}
              <span>Satvidi</span> helps the business to build the world class
              Mobile Application that facilitate the business needs for Native
              and Hybrid platforms.
            </p>
          </div>
          <div className="rightdiv">
            <img src="mob1.gif" alt="" />
          </div>
        </div>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="crossplat.png" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>Native App Development</h1>
            <p>
              At <span>Satvidi</span>, We build robust, fast, responsive and
              high-performing native apps for both Android and iOS.
            </p>
          </div>
        </div>
      </First>
      <Second>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="android.png" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>Android Application Development</h1>
            <p>
              At <span>Satvidi</span>, our goal is to serve the client needs
              with full cycle of Android mobile app services which covers
              design, development, testing, deployment and maintenance/support.
            </p>
          </div>
        </div>
      </Second>
      <Third>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="iphone.png" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>iOS Application Development</h1>
            <p>
              We offer iOS service suit with end-to-end custom products that
              comprehend the full range iOS devices with security,
              high-performing and scalability.
            </p>
          </div>
        </div>
      </Third>
      <Fourth>
        <div className="container">
          <div
            className="pic"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src="hybrid.png" alt="" />
          </div>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <h1>Hybrid App Development</h1>
            <p>
              At <span>Satvidi</span>, we develop Hybrid app development with
              cross platform features can help the business to market the app in
              cost-effective manner.
              <br />
              <br />
              We specialize in:
              <br />
              Flutter
              <br />
              Unity
              <br />
              React Native
              <br />
              Unreal
            </p>
          </div>
        </div>
        <div className="btn-contact">
          <p>Ready to Ask Us?</p>
          <a href="contact us">contact now</a>
        </div>
      </Fourth>

      <Footer>
        <div className="footer">
          <div className="atags">
            <a href="/">Home/</a>
            <a href="/web">Web App/</a>
            <a href="/mobile">Mobile App/</a>
            <a href="/ecom">E-commerce /</a>

            <a href="contact us">Contact us</a>
          </div>
          <p>
            <i class="fa fa-copyright"></i> 2021 Satvidi Technologies
          </p>
        </div>
      </Footer>
    </Main>
  );
};
const Footer = styled.div`
  height: 20vh;
  width: 100%;
  .footer {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: black;
    a {
      padding: 0 0 0 5px;
      color: white;
      font-family: "PT Serif", serif;
      @media (max-width: 700px) {
        font-size: 12px;
      }
    }
    a:hover {
      color: blue;
    }
    p {
      color: white;
      text-align: center;
      font-size: 15px;
      i {
        color: white;
      }
    }
  }
`;
const Main = styled.div`
  background-color: #0f123d;
  color: white;
  overflow-x: hidden;

  h1 {
    color: #bee4f2;
    letter-spacing: 1px;
    padding: 40px;
    font-family: "PT Serif", serif;

    @media (max-width: 700px) {
      padding: 20px;
      font-size: 25px;
    }
  }

  p {
    color: white;
    padding-left: 45px;
    padding-bottom: 5px;
    width: 75%;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    word-spacing: 1px;
    font-family: "PT Serif", serif;

    @media (max-width: 700px) {
      padding-left: 30px;
      padding-bottom: 10px;
      font-size: 15px;
      line-height: 25px;
      width: 95%;
    }
    span {
      color: #f9562f;
      font-family: "PT Serif", serif;
    }
  }
`;
const First = styled.div`
  .opening {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }

    .leftdiv {
      width: 80%;
      margin-top: 8%;
      @media (max-width: 700px) {
        margin-top: 20%;
      }
      h1 {
        @media (max-width: 700px) {
          width: 100%;
        }
      }
      p {
        width: 90%;
        font-size: 25px;
        margin-top: 30px;
        line-height: 30px;
        @media (max-width: 700px) {
          width: 120%;
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
    .rightdiv {
      width: 40%;
      img {
        padding-top: 80px;
        @media (max-width: 700px) {
          width: 250%;
          padding: 20px;
        }
      }
    }
  }
  .container {
    height: 75vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 5%;
    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -25%;
      margin-left: 25%;
    }
  }
  .pic img {
    width: 500px;
    padding-left: 30px;
    @media (max-width: 700px) {
      width: 300px;
      padding-left: 0px;
      margin-left: -4%;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 50px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
  }
`;
const Second = styled.div`
  .container {
    height: 75vh;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      margin-top: -5%;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -1%;
      margin-left: 25%;
    }
  }
  .pic img {
    width: 300px;

    @media (max-width: 700px) {
      width: 240px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
const Third = styled.div`
  .container {
    height: 75vh;

    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -1%;
      margin-left: -40%;
    }
  }
  .pic img {
    width: 600px;
    @media (max-width: 700px) {
      width: 500px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;
const Fourth = styled.div`
  .container {
    height: 75vh;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column-reverse;
      margin-bottom: 10%;
    }
    .head {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      font-family: "PT Serif", serif;
    }
  }
  .pic {
    margin-top: 3%;

    width: 40%;
    @media (max-width: 700px) {
      width: 100%;
      margin-top: -1%;
      margin-left: 25%;
      margin-bottom: 50%;
    }
  }
  .pic img {
    width: 500px;
    @media (max-width: 700px) {
      width: 250px;
    }
  }
  .container .text {
    width: 60%;
    padding-left: 40px;
    margin-top: -30px;
    @media (max-width: 700px) {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 30px;
    }
    p {
      width: 80%;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
  .bottdiv {
    p {
      width: 80%;
      margin-top: -5%;
      padding-left: 80px;
      margin-bottom: 5%;
      @media (max-width: 700px) {
        width: 100%;
        padding-left: 10px;
      }
    }
  }
  .btn-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    height: 20vh;
    background-image: url("Meteor.svg");
    /* background-color: #ff8906; */
    @media (max-width: 700px) {
      margin-top: -10%;
    }
    p {
      color: black;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      font-family: "PT Serif", serif;
    }
    a {
      margin-top: 2%;
      text-decoration: none;
      background-color: #1c75cf;
      font-family: "PT Serif", serif;
      padding: 10px 20px 10px 20px;
      border: 1px solid blue;
      border-radius: 50px;
      color: white;
      transition: 0.5s;
    }
    a:hover {
      background-color: #000000;
      color: white;
      transition: 0.5s;
      border: 1px solid whitesmoke;
    }
  }
`;

export default Web;
